import React, { useState } from "react";
// import PropTypes from 'prop-types'

export default function Textform(props) {
  const handleUpClick = () => {
    // console.log("Uppercase was clicked " + text);
    let newText = text.toUpperCase();
    setText(newText);
    props.showAlert('Converted to Uppercase!', 'success')
  };

  const handleonChange = (event) => {
    // console.log("On change");
    setText(event.target.value);
  };

  const handleDnClick = () => {
    // console.log("Lowercase was clicked " + text)
    let newText = text.toLowerCase();
    setText(newText);
    props.showAlert('Converted to Lowercase!', 'success')
  };
  const handleClClick = () => {
    // console.log("Lowercase was clicked " + text)
    let newText = "";
    setText(newText);
    props.showAlert('Text has been cleared', 'success')
  };

  const handleCopy = () => {
    console.log("Text Copied");
    var text = document.getElementById("myBox");
    text.select();
    navigator.clipboard.writeText(text.value);
    document.getSelection().removeAllRanges();
    props.showAlert('Text has been copied!', 'success')
  };

  const handleExtraSpaces = () => {
    let newText = text.split(/[  ]+/);
    setText(newText.join(" "))
    props.showAlert('Extra spaces have been cleared', 'success')
  };

  const [text, setText] = useState("");
  return (
    <>
      <div className="container" style={{ color: props.mode === 'dark' ? 'white' : 'black' }}>
        <h1>{props.heading}</h1>
        <div className="mb-3">
          <textarea
            className="form-control"
            value={text}
            onChange={handleonChange}
            style={{ backgroundColor: props.mode === 'dark' ? '#13466e' : 'white', color: props.mode === 'dark' ? 'white' : 'black' }}
            id="myBox"
            rows="8"
          ></textarea>
        </div>
        <button disabled={text.length===0} className="btn btn-primary mx-2 my-2" onClick={handleUpClick}>
          Convert to upper case
        </button>

        <button disabled={text.length===0} className="btn btn-primary mx-2 my-2" onClick={handleDnClick}>
          Convert to lower case
        </button>
        <button disabled={text.length===0} className="btn btn-primary mx-2 my-2" onClick={handleClClick}>
          Clear Text
        </button>
        <button disabled={text.length===0} className="btn btn-primary mx-2 my-2" onClick={handleCopy}>
          Copy Text
        </button>
        <button disabled={text.length===0} className="btn btn-primary mx-2 my-2" onClick={handleExtraSpaces}>
          Remove Extra Spaces
        </button>
      </div>
      <div className="container my-3 " style={{ color: props.mode === 'dark' ? 'white' : 'black' }}>
        <h2>Your text summary</h2>
        <p>
          {text.split(" ").filter((element) => { return element.length !== 0 }).length} words and {text.length} characters
        </p>
        <p>{0.008 * text.split(" ").filter((element) => { return element.length !== 0 }).length} Minutes read </p>
        <h2>Preview</h2>
        <p>{text.length > 0 ? text : "Nothing to perview"}</p>
        <div style={{align:'left'}}>
          <p>Copyright &copy; Hamza Sahib Dad | All Rights Reserved</p>
        </div>
      </div>
    </>
  );
}
